.SignupForm {
  display: flex;
  height: 100vh;
  min-height: 750px !important;
  margin-top: calc(-1*var(--header-height));
}

.SignupScreenImage .CatchLine {
  position: absolute;
  bottom: 10%;
  left: 10%;
  font-family: 'Satoshi-Medium';
  font-size: 2.6em;
}

.LoginForm {
  display: flex;
  height: 100vh;
  min-height: 600px;
  margin-top: calc(-1*var(--header-height));
}

.LoginForm svg,
.SignupForm svg {
  width: 100%;
  height: 100%;
}

svg.TitleSvg  {
  height: 6em;
  width: 100%;
  margin-bottom: var(--margin-normal);
}

.LoginScreenImage,
.SignupScreenImage {
  position: relative;
  width: 50%;
  overflow: hidden;
}

.LoginScreenImage img,
.SignupScreenImage img {
  width: 100%;
  height: calc(100vh);
  object-fit: cover;
}

.LoginScreenImage img {
  min-height: 600px;
}
.SignupScreenImage img {
  min-height: 750px;
}

.LoginFormElementWrapper,
.SignupFormElementWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--background-color);
}

.LoginFormElementWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--background-color);
  min-height: 600px;
}

.SignupFormElementWrapper {
  min-height: 750px;
}

.LoginFormElement,
.SignupFormElement {
  max-width: 500px;
  width: calc(100% - 2 * var(--content-margin));
}

.LoginSubmitButton {
  margin: 2em 0;
}

.PageInfo {
  margin-top: var(--margin-normal);
  font-size: var(--font-size-small);
  text-align: center;
}

.ErrorBox {
  font-family: 'Satoshi-Bold';
  background: var(--backdrop-color);
  width: 100vw;
  padding: var(--margin-normal) 0;
  text-align: center;
  margin: var(--margin-normal) 0;
  margin-left: calc(-1 * var(--content-margin));
  transform: scale(0);
}

.InputErrorMessage {
  color: var(--error-color)
}

.FormDivider {
  width: 100%;
  display: flex;
  margin: 1.5em 0;
}

.FormDivider>div {
  padding: 0 1em;
}

.FormDivider::before,
.FormDivider::after {
  width: 100%;
  content: '';
  transform: translateY(50%);
  border-top: 1px solid var(--text-color);
}

.SuccessIcon * {
  fill: var(--success-color) !important;
  stroke: none !important;
}

.ErrorIcon * {
  fill: var(--error-color) !important;
  stroke: none !important;
}

.SuccessIcon,
.ErrorIcon {
  margin-top: var(--margin-extra-large);
  width: 7em;
  height: 7em;
}

.StatusPage {
  text-align: center;
  margin: 0 auto;
  width: 80%;
}

.Help {
  font-size: var(--font-size-small);
}

.ResetPasswordForm,
.ForgotPasswordForm {
  margin-top: var(--margin-normal);
}

.GoogleSignup h1 {
  font-size: var(--font-size-large);
  margin-bottom: var(--margin-normal);
}

.GoogleSignupDescription {
  margin-bottom: var(--margin-normal);
}


@media screen and (min-width: 1200px) {
  .LoginFormElementWrapper,
  .SignupFormElementWrapper {
    width: 50%;
    margin: 0;
    height: calc(100vh);
  }

  .LoginFormElement,
  .SignupFormElement {
    width: 60%;
  }

}