.NoLivestreamsLoggedIn {
    text-align: center;
    padding-bottom: 120px;
    background: var(--background-color);
    padding-top: 1px;
}

.Wrapper {
    width: 80%;
    margin-left: 10%;
}

.GradientBlock {
    height: 51px;
    width: 100%;
    background: linear-gradient(90deg, var(--secondary-color), transparent);
    margin: 64px 0 32px 0;
}

.NoLivestreamsLoggedIn h1 {
    font-size: 2.2em;
    font-family: 'Satoshi-Medium';
}

.FormDivider {
    width: 100%;
    display: flex;
    margin: 1.5em 0;
}

.FormDivider>div {
    padding: 0 1em;
}

.FormDivider::before,
.FormDivider::after {
    width: 100%;
    content: '';
    transform: translateY(50%);
    border-top: 1px solid var(--text-color);
}

.StartStreamButton {
    width: auto;
    padding: 12px 28px;
    margin: 0;
}

.StartStreamButton a {
    color: var(--text-color-dark);
}

@media screen and (min-width: 750px) {
    .NoLivestreamsLoggedIn {
        padding-bottom: 400px;
    }

    .GradientBlock {
        width: calc(100% - 2 * var(--content-margin));
        margin-left: var(--content-margin);
    }

    .NoLivestreamsLoggedIn h1 {
        font-size: 3em;
        width: 100%;
        text-align: center;
        margin-bottom: 1em;
    }

    .NoLivestreamsLoggedIn .FormDivider {
        width: 50%;
        margin: 2em auto;
    }

}


@media screen and (min-width: 1000px) {

    .NoLivestreamsLoggedIn {
        padding-bottom: 200px;
    }

    .NoLivestreamsLoggedIn h1 {
        width: 60%;
        margin: 0 auto;
    }

    .NoLivestreamsLoggedIn .FormDivider {
        width: 30%;
    }

}

@media screen and (min-width: 1500px) {
    .NoLivestreamsLoggedIn h1 {
        width: 40%;
        margin: 0 auto;
    }

    .NoLivestreamsLoggedIn .FormDivider {
        width: 20%;
    }

}

@media screen and (min-width: 2250px) {
    .NoLivestreamsLoggedIn h1 {
        width: 30%;
        margin: 0 auto;
    }

    .NoLivestreamsLoggedIn .FormDivider {
        width: 10%;
    }

}