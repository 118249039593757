.UserList {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: calc(100vw - 2 * var(--content-margin));
  justify-content: space-between;
  z-index: 2;
}

.Circle .UserList {
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
}

.UserList>a {
  width: 48%;
  overflow: hidden;
  margin-bottom: var(--margin-normal);
}

.Circle .UserList>a {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
}

.TopListenerImageWrapper {
  overflow: hidden;
  position: relative;
  z-index: -1;
}

.Circle .TopListenerImageWrapper {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.TopListenerImageWrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, rgba(5, 5, 6, 0.93) 0%, rgba(5, 5, 6, 0) 100%);
}

.Circle .TopListenerImageWrapper::after {
  display: none;
}


.TopListenerImage,
.TopListenerImageWrapper svg {
  width: 100%;
  height: 45vw;
  max-height: 256px;
}

.Circle .TopListenerImage,
.TopListenerImageWrapper svg {
  width: 100%;
  height: 100%;
}

.TopListenerImage {
  object-fit: cover;
}

.TopListenerImageWrapper svg {
  transform: scale(2.3) translateX(-23%);
}

.Circle .TopListenerImageWrapper svg {
  transform: none;
}

.Title {
  margin-bottom: var(--margin-normal);
  margin-top: var(--margin-extra-large);
  text-align: left;
}

.Circle .UserName {
  display: none;
}

.Circle .LeftWrapper {
  display: flex;
  align-items: center;
  width: 60%;
}

.Circle .CircleUsername {
  margin-left: 12px;
  width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.UserName {
  position: absolute;
  bottom: var(--margin-normal);
  overflow: hidden;
  text-overflow: ellipsis;
  left: var(--margin-extra-small);
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Bold';
  max-height: 40%;
  width: 87%;
  z-index: 1;
  text-align: left;
}


@media screen and (min-width: 750px) {
  .Title {
    margin-bottom: 40px;
  }

  .UserList {
    justify-content: flex-start;
  }

  .UserList>a {
    width: 23%;
    margin-left: calc(8% / 3);
    font-size: 0.8em;
  }

  .UserList>a:nth-child(4n+1) {
    margin-left: 0;
  }


  .UserList img {
    height: calc((100vw - 2 * var(--content-margin)) * 0.24);
    max-height: 256px;
    max-width: 256px;
  }


}

@media screen and (min-width: 1000px) {

  .UserList {
    width: 100%;
  }

  .UserList img {
    height: calc(80vw * 0.24);
  }

  .UserList a .UserName {
    position: relative;
    width: 100%;
    bottom: auto;
    font-size: 1.6em;
    margin-top: 11px;
    font-family: 'Satoshi-Medium';
    margin-left: -8px;
  }

  .TopListenerImageWrapper::after {
    display: none;
  }

  .Circle h2 {
    margin: 0;
    margin-bottom: 12px;
    font-family: 'Satoshi-Bold';
    font-size: var(--font-size-normal);
  }

}

@media screen and (min-width: 1350px) {
  .UserList img {
    height: calc(70vw * 0.24);
  }
}

@media screen and (min-width: 1600px) {
  .UserList>a {
    margin-left: 24px;
  }
}
