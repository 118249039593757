header {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-lighter-color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}

.headerExpandedLinks {
  margin-left: var(--content-margin);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerLink,
.headerLink:visited {
  margin-right: 24px;
  color: var(--color-grey);
  text-decoration: none;
  font-family: 'Satoshi-Medium';
}

.headerLink:hover {
  color: var(--text-color);
}

.headerLink.active,
.headerLink.active:visited {
  color: var(--text-color);
}

.headerExpandedLinks>a:nth-child(2) {
  margin-left: calc(12px + 24px);
}

.expandedAccountSignLinks .avatarWrapper {
  width: 32px;
  height: 32px;
  margin: 0 12px;
}

.expandedAccountSignLinks .avatarWrapper svg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.headerUsername {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-grey);
}



header>div {
  margin-right: var(--content-margin);
}

header .expandedAccountSignLinks {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

header .expandedAccountSignLinks>a {
  margin-left: 12px;
}

img,
.logo,
svg {
  color: white;
  height: 2.4em;
  width: auto;
  opacity: 1;
  transition: 0.16s ease-out;
}

header .logo .cls-2 {
  fill: var(--text-color);
  stroke: none;
}

header .logo .cls-1 {
  fill: none;
  stroke: none;
}

header .searchIcon * {
  fill: none !important;
}

img.hidden {
  opacity: 0;
  transition: 0.16s ease-out;
}

header .hamburger * {
  fill: var(--text-color);
  stroke: none;
}

nav {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100%;
  background: var(--background-color);
  z-index: 1000;
}

.headerASccountWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-left: var(--content-margin);
}

.SearchView {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100%;
  background: var(--background-color);
  z-index: 1000;
  opacity: 0;
  align-items: center;
}

nav.closed,
.SearchView.closed {
  pointer-events: none;
}

.hamburger {
  position: relative;
}

.hamburger.open {
  z-index: 10000;
}

.searchIcon {
  height: 2.5em;
  width: 2.5em;
  margin-right: var(--margin-normal);
}

#menuIcon {
  width: 2.5em;
  height: 2.5em;
}

#menuIcon>.stroke {
  fill: var(--text-color);
}

.MenuLinkWrapper {
  display: flex;
  align-items: center;
  font-size: var(--font-size-extra-large);
  font-family: 'Satoshi-Medium';
  margin-bottom: var(--margin-large);
  margin-left: var(--content-margin);
}

.MenuLinkWrapper.active {
  color: var(--primary-color);
}

.MenuLinkWrapper.active svg * {
  fill: var(--primary-color);
}

.MenuLinkWrapper:first-child {
  margin-top: var(--header-height);
}

.MenuLinkWrapper img,
.MenuLinkWrapper svg {
  width: 1.4em;
  height: 1.4em;
  margin-right: var(--margin-normal);
}

.MenuLinkWrapper svg * {
  fill: var(--text-color);
  stroke: none;
}


.MenuLogo {
  position: absolute;
  bottom: var(--margin-normal);
  right: var(--content-margin);
}

.MenuFooter {
  position: absolute;
  font-size: var(--font-size-normal);
  bottom: var(--margin-normal);
  left: var(--content-margin);
  color: var(--color-grey);
}

.MenuFooter>div {
  margin-top: var(--margin-extra-small);
}

.CopyRight {
  font-size: var(--font-size-small);
  color: var(--text-color);
}

.NavSkim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(10, 10, 10, 0.8);
  z-index: 100;
  opacity: 0;
  pointer-events: none;
}

@media screen and (min-width: 750px) {
  .headerExpandedLinks {
    margin-left: 12px;;
  }
  .expandedAccountSignLinks {
    margin-right: 12px;
  }
}

@media screen and (min-width: 900px) {
  .SearchView {
    position: relative;
    width: auto;
    height: auto;
    margin-right: 24px;
  }

  .accountLink {
    margin-left: 0 !important;
  }

  .accountLink:hover .headerUsername {
    color: var(--text-color) !important;
  }

  .headerExpandedLinks {
    margin-left: 24px;
  }

  .expandedAccountSignLinks button {
    height: 32px;
    padding: 0 16px;
    font-family: 'Satoshi-Medium';
    font-size: var(--font-size-normal);
    font-size: 0.95em;
  }

  .expandedAccountSignLinks button.login {
    color: var(--color-grey);
  }

  .expandedAccountSignLinks button.signUp {
    font-family: 'Satoshi-Bold';
  }

  header>div {
    margin-right: 24px;
  }

  svg.searchIcon * {
    stroke: var(--color-grey) !important;
  }

  svg.searchIcon {
    margin-right: 0;
  }

  .searchIcon.action {
    cursor: auto;
    margin-right: 3px;
  }

}

@media screen and (min-width: 1000px) {
  .headerUsername {
    max-width: 120px;
  }
}