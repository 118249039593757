.SubscriptionManager {
  width: 100%;
}

.SubscriptionWrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: var(--margin-normal);
}

.Subscription {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.SubscriptionLeft {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  align-items: center;
}

.AvatarWrapper {
  margin-right: var(--margin-normal);
}

h1 {
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Medium';
  margin-top: 1em;
}

.AvatarWrapper {
  position: relative;
  width: var(--avatar-wrapper-size);
  height: var(--avatar-wrapper-size);
  align-self: center;
  border-radius: 50%;
  background-color: var(--backdrop-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvatarWrapper svg {
  width: var(--avatar-generated-size);
  height: var(--avatar-generated-size);
}

.Avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (min-width: 400px) {
  .Subscription {
    margin: 12px 0;
  }
  .AvatarWrapper {
    max-width: 100px;
    max-height: 100px;
  }
}

@media screen and (min-width: 1000px) {
  .ContentWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .SubscriptionWrapper {
    flex-flow: row wrap;
    height: fit-content;
    margin-top: 0;
  }
  .Subscription {
    margin-top: 0;
    width: 23%;
    flex-flow: column nowrap;
    align-items: center;
    margin-right: 38px;
    max-width: 160px;
  }
  .SubscriptionLeft {
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    font-size: 20px;
    font-family: 'Satoshi-Medium';
  }
  .AvatarWrapper {
    margin: 0;
    max-width: 160px;
    max-height: 160px;
    margin-bottom: 18px;
  }
  .SubscriberCount {
    padding: 8px 0 0 0;
    font-size: 12px;
  }

  .ContentWrapper > *:first-child {
    width: 70%;
  }
  .ContentWrapper > *:last-child {
    width: 30%;
  }
  .NoSubscriptions {
    max-width: 500px;
  }

  button {
    font-size: 12px !important;
  }

  .Subscription button {
    font-size: 12px !important;
    opacity: 0;
  }

  .Subscription:hover button {
    opacity: 1;
  }

  .UnsubscribeAll {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}