.Footer {
    background-color: var(--primary-color);
    color: var(--text-color-dark);
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--player-height);
}

.Footer > div {
    margin: var(--margin-large) var(--content-margin);
}

.Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Satoshi-Bold';
    padding-bottom: 1.5em;
}

.Left > div {
    margin: var(--margin-mini) 0;
}

.Right {
    font-family: 'Satoshi-Medium';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.Right > svg {
    height: 1.5em;
    width: fit-content;
}

.Footer a {
    color: var(--text-color-dark);
    text-decoration: none;
}