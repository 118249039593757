.Checkbox {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 6px 0;
  cursor: pointer;
}

.CheckboxInput {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}

.Checkmark {
  margin-right: 8px;
  height: 14px;
  width: 14px;
  background-color: var(--backdrop-color);

}

.CheckboxInput:checked~.Checkmark {
  background-color: var(--primary-color);
}

.Required {
  color: var(--error-color);
  margin-left: 3px;
}