.EditProfileForm {
  padding-bottom: var(--player-height);
}

.UserProfile {
  margin-bottom: calc(var(--player-height) + var(--margin-small));
}

.EditProfileForm label {
  margin-top: var(--margin-normal);
  text-transform: capitalize;
  font-family: 'Satoshi-Regular';
}

.EditProfileForm input[type=text] {
  padding: 0;
  font-size: 1em;
}

.EditProfileForm textarea {
  padding: 0;
  margin-top: var(--margin-´mini);
}

.UserProfile .AvatarWrapper,
.EditProfileForm .AvatarWrapper {
  position: relative;
  width: 45vw;
  height: 45vw;
  align-self: center;
  border-radius: 50%;
  background-color: var(--backdrop-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--margin-extra-small);
}

.UserProfile .AvatarWrapper svg,
.EditProfileForm .AvatarWrapper svg {
  width: var(--avatar-generated-size);
  height: var(--avatar-generated-size);
  border-radius: 50%;
}

.EditAvatarWrapper {
  margin-top: 0;
}

.Avatar {
  width: 96%;
  height: 96%;
  border-radius: 50%;
  object-fit: cover;
}

.ProfileHeaderWrapper {
  display: flex;
  flex-direction: column;
}

.ProfileHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--margin-extra-small);
}

.ProfileUserName {
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Bold';
  margin-bottom: var(--margin-mini);
  height: 1.3em;
}

.ProfileEditButton {
  font-family: 'Satoshi-Medium';
  text-transform: capitalize;
}

.ProfileSubscribers {
  font-size: var(--font-size-normal);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ProfileBio {
  font-size: var(--font-size-normal);
  width: 90%;
  margin-bottom: var(--margin-normal);
  white-space: pre-line;
}

.Delete {
  position: absolute;
  background-color: var(--background-color);
  border-radius: 50%;
  right: 0.5em;
  top: 0.5em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Delete svg {
  height: 1.3em;
  width: 1.3em;
}

.Delete svg *, .CenterText svg * {
  fill: var(--text-color);
  stroke: none;
}

.CenterText {
  margin: var(--margin-small);
  align-self: center;
  display: flex;
  align-items: center;
  font-size: var(--font-size-normal);
}

.CenterText svg {
  height: 1.5em;
  width: 1.5em;
  margin-right: var(--margin-extra-small);
}

.BioTextarea {
  background-color: var(--background-color);
  border-bottom: 1.5px solid var(--primary-color);
  height: 15vh;
  height: fit-content;
}

.NoStreams {
  margin-top: var(--margin-small);
}

.StreamHistoryEntry {
  background-color: var(--background-lighter-color);
  margin: var(--margin-small) 0 0 0;
}

.TrackList {
  overflow: hidden;
  padding: var(--margin-small);
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0px 1px 0px var(--secondary-color) inset;
}

.Track {
  display: flex;
  margin: var(--margin-small) 0;
}

.TrackIndex {
  min-width: 3em;
  font-family: 'Satoshi-Medium';
  font-size: var(--font-size-small);
  align-self: center;
}

.TrackTitle {
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-small);
}

.TrackArtist {
  font-family: 'Satoshi-Medium';
  font-size: var(--font-size-small);
}

.Username:disabled {
  background: var(--background-color);
}

.CharacterCount {
  align-self: flex-end;
  font-size: var(--font-size-small);
  margin-bottom: 1em;
}

.LastStreamPreview {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-normal);
  padding: var(--margin-small);
}

.LastStreamPreviewRight {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;
  font-size: var(--font-size-small);
}

.DeleteStream {
  display: flex;
}

.DeleteStream svg {
  width: 1.3em;
  height: 1.3em;
  margin-right: var(--margin-mini)
}

.LastStreamPreview .Date {
  color: var(--color-grey)
}

.LastStreamPreview .Title {
  font-family: 'Satoshi-Bold';
  margin-bottom: var(--margin-extra-small);
}

.LastStreamPreview .Genre {
  font-family: 'Satoshi-Regular';
  font-size: var(--font-size-small);
  margin-bottom: var(--margin-extra-small);
}

.LastStreamPreview .Duration {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Medium';
  color: var(--color-grey)
}

.LastStreamPreview .Duration svg {
  width: 1.3em;
  height: 1.3em;
  margin-right: var(--margin-mini);
}

.LastStreamPreview .Duration svg *,
.LastStreamPreview .DeleteStream svg * {
  fill: var(--text-color);
  stroke: none;
}

.TrackListTitle {
  margin-top: var(--margin-small);
  font-family: 'Satoshi-Medium';
}


/* Account */

.AccountPage {}

.AccountPage .UserInfo {
  margin: var(--margin-extra-large) 0;
}

.AccountPage .AvatarWrapper {
  border-radius: 50%;
  width: 4em;
  height: 4em;
  margin: 0 var(--margin-normal) 0 0;
  background: var(--background-lighter-color);
}

.AccountUserName {
  height: 1.6em;
}

.AccountPage .AvatarWrapper>.PreviewAvatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AccountPage .AvatarWrapper svg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


.AccountPage .ActionLinkWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: var(--margin-extra-small) 0;
}

.AccountPage .Icon, .ActionLinkWrapper svg, .UserProfile .Icon {
  margin-right: var(--margin-extra-small);
  width: 1.6em;
  height: 1.6em;
}

.ActionLinkWrapper svg *, .Icon *, .EditIcon {
  fill: var(--text-color) !important;
  stroke: none !important;
}

.UserInfo .EditIcon {
  margin-right: 3px;
}

.AccountPage .ActionLink, .AccountPage .ActionLink a {
  font-size: var(--margin-normal);
  font-family: 'Satoshi-Medium';
}

.AccountPage .CurrentLivestreamTitle {
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Medium';
  margin-top: var(--margin-extra-large);
}

.AccountPage .CurrentLivestreamTitle .Icon {
  width: 1.3em;
  height: 1.3em;
  margin-left: var(--margin-mini);
}

.AccountPage .CurrentLivestream {
  margin-top: var(--margin-normal);
  background-color: var(--background-lighter-color);
  padding: var(--margin-normal) var(--margin-small);
  margin-bottom: var(--margin-large);
}

.AccountPage .CurrentLivestream>div {
  margin-bottom: var(--margin-extra-small);
}

.AccountPage .StreamTitle {
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Bold';
  color: var(--primary-color);

}

.AccountPage .TrackInfo {
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Bold';
}

.AccountPage .CurrentLivestream .Genre {
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Medium';
  margin-bottom: var(--margin-small);
}

.AccountPage .Statistics {
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Medium';
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
}

.AccountPage .Statistics>div {
  margin: var(--margin-mini) 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.AccountPage .Statistics>div svg {
  width: 1.3em;
  height: 1.3em;
  margin-right: var(--margin-mini);
}

.AccountPage .Statistics>div svg * {
  fill: var(--text-color);
  stroke: none;
}

.AccountPage .CredentialsButton {
  width: 55%;
  min-width: fit-content;
  margin: var(--margin-extra-large) 0;
}

.LiveSection {}

.LiveTitle {
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Bold';
  padding-bottom: 4px;
  border-bottom: 1px solid var(--secondary-color);
  margin: var(--margin-mini) 0;
}

.StreamInfo {
  margin: var(--margin-small) 0;
}

.PlayIconWrapper {
  text-align: center;
  border-radius: 50%;
  background: var(--primary-color);
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PlayIconWrapper>svg {
  height: 1.99em;
}

.PlayIconWrapper>svg * {
  fill: var(--background-color);
  stroke: none;
}



.ServerName {
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Medium';
}

.TrackWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: var(--margin-small) 0;
}

.TrackWrapper>div:first-child {
  margin-right: var(--margin-small);
}

.CurrentStreamGenre {
  font-size: var(--font-size-small);
}

.LiveBox {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 20%);
  background: var(--secondary-color);
  padding: 3px 6px;
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-small);
}

@media screen and (min-width: 900px) {
  .ProfileHeaderWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 56px;
    margin-bottom: 32px;
  }

  .ProfileHeaderWrapper .AvatarWrapper {
    width: 190px;
    height: 190px;
    margin-right: 36px;
  }

  .ProfileHeader {
    flex-direction: column;
    font-family: 'Satoshi-Medium';
  }

  .ProfileUserName {
    font-size: 48px;
    font-family: 'Satoshi-Medium';
  }

  .ProfileUserActions {
    display: flex;
  }

  .ProfileContentWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .ProfileLeftAside {
    width: 60%;
  }

  .ProfileLastStreams {
    width: 100%;
  }

  .ProfileAside {
    width: 35%;
    overflow: hidden;
  }

  .Avatar {}

  .ProfileHeader .ProfileUserActions>div>div,
  .ProfileHeader .ProfileUserActions>a>div {
    font-family: 'Satoshi-Medium';
  }

  .ProfileHeader .ProfileUserActions>div>svg * {
    fill: var(--text-color);
    stroke: none;
  }

  .ProfileHeader .ProfileUserActions>a>svg {
    margin-right: 3px;
  }

  .ProfileUserActions>* {
    margin-right: 12px;
  }

  .About>div {
    margin-top: var(--margin-small);
    white-space: pre-line;
  }

  .ProfileSubscribers {
    margin-top: 8px;
  }

  .ProfileUserActions button {
    padding: 8px 10px;
  }

  .ServerNameAndGenre {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .LiveSection {
    margin-bottom: 62px;
  }

  .ServerName {
    font-size: 36px;
    font-family: 'Satoshi-Medium';
    width: 80%;
  }
}