:root {
  --primary-color: #FFDC14;
  --secondary-color: #552AFF;
  --background-color: #050506;
  --background-lighter-color: #151417;
  --backdrop-color: #201F23;
  --color-dark-grey: #151417;
  --color-grey: #C8C8BC;
  --color-purple: #A12AFF;
  --color-pink: #FF1F55;
  --error-color: #FF5747;
  --success-color: #47FF7B;
  --text-color: white;
  --text-color-dark: #0b0a0c;

  --content-margin: 12px;
  --form-border-radius: 0px;
  --player-height: 60px;
  --header-height: 58px;

  --font-size-extra-small: 0.6em;
  --font-size-small: 0.8em;
  --font-size-normal: 1em;
  --font-size-large: 1.5em;
  --font-size-extra-large: 2em;

  --margin-mini: 4px;
  --margin-extra-small: 8px;
  --margin-small: 12px;
  --margin-normal: 18px;
  --margin-large: 24px;
  --margin-extra-large: 32px;

  --avatar-wrapper-size: 15vw;
  --avatar-generated-size: 66%;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: Satoshi-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-normal);
}

::selection {
  background: var(--secondary-color);
}
::-moz-selection {
  background: var(--secondary-color);
}

nav {
  display: flex;
  flex-flow: column;
}

a, a:visited {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}


h1, h2, h3, a {
  font-family: 'Satoshi-Bold';
  font-weight: 500;
  margin-block-end: 0px;
  ;
}

h2 {
  font-size: var(--font-size-large);
}

button, input[type="submit"] {
  font-family: 'Satoshi-Bold';
  padding: 12px;
  border: none;
  box-shadow: none;
  border-radius: var(--form-border-radius);
  background-color: var(--primary-color);
  width: -webkit-fill-available;
  text-transform: capitalize;
  font-size: var(--font-size-normal);
  margin: var(--margin-normal) 0;
  color: var(--text-color-dark);
  cursor: pointer;
}


button:disabled, input[type="submit"]:disabled {
  opacity: 0.55;
}

button.red {
  background: var(--error-color);
  color: var(--text-color);
}

button.green {
  background: var(--success-color);
  color: var(--text-color);
}

button.grey {
  background: var(--backdrop-color);
  color: var(--text-color);
}

button.transparent {
  background: transparent;
  color: var(--text-color);
}

button.grey svg * {
  fill: var(--text-color) !important;
}

button.primary {
  background: var(--primary-color);
  color: var(--text-color-dark);
}

button svg * {
  fill: var(--text-color-dark) !important;
}

button.small {
  width: fit-content;
  text-transform: capitalize;
  letter-spacing: normal;
  padding: 6px 12px;
}

.action {
  cursor: pointer;
}

input[type="text"], input[type="password"], input[type="email"], textarea {
  font-family: 'Satoshi-Medium';
  padding: 10px;
  border: none;
  border-radius: var(--form-border-radius);
  background: var(--backdrop-color);
  color: var(--text-color);
  outline: none !important;
  width: -webkit-fill-available;
  font-size: var(--font-size-normal);
  margin-bottom: var(--margin-small);
}

.pageTitle {
  height: 100%;
  margin-bottom: 1.5em;
  text-transform: uppercase;
  margin-top: var(--margin-extra-large);
}

.pageTitle.withText {
  margin-top: -1em;
  margin-left: auto;
  margin-right: auto;
}

.pageTitleText {
  font-family: 'Satoshi-Medium';
  font-size: var(--font-size-large);
  text-align: center;
  margin-top: 0;
}

.pageTitle.up {
  margin-top: var(--margin-extra-large)
}

.pageTitleWrapper {
  display: flex;
}



.pageTitleText.down {
  margin-top: -1em;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: var(--header-height);
}

label {
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Medium';
  margin-bottom: var(--margin-extra-small);
  text-transform: capitalize;
  color: #C8C8BC;
}

.textLink, .textLink:visited, .textLink:active {
  font-family: inherit;
  color: var(--primary-color);
}

.inputErrorMessage {
  height: var(--font-size-small);
  margin-bottom: var(--margin-small);
  font-size: var(--font-size-small);
  color: var(--error-color)
}

.infoPage {
  width: calc(100vw - 2 * var(--content-margin));
  margin-left: var(--content-margin);
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  margin-bottom: calc(var(--header-height) * 1.5);
}


/* Flex */

.flex {
  display: flex;
}

.flex.row {
  flex-flow: row nowrap;
}

.flex.column {
  flex-flow: column nowrap;
}

.flex.spaceBetween {
  justify-content: space-between;
}

.flex.alignCenter {
  align-items: center;
}


/* font */

.fontSizeSmall {
  font-size: var(--font-size-small);
}

.fontSizeNormal {
  font-size: var(--font-size-normal);

}

.fontSizeLarge {
  font-size: var(--font-size-large);

}

.fontBold {
  font-family: 'Satoshi-Bold';
}

.fontMedium {
  font-family: 'Satoshi-Medium';
}

/* Action Bar */

.actionBar {
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.actionBar.center {
  align-items: center;
}

.actionBar svg {
  width: 2.5em;
  height: 2.5em;
}

.actionBar .back {
  margin-left: -0.8em;
}

.actionWithIconAndText {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  font-family: 'Satoshi-Medium';
}

.actionWithIconAndText.center {
  align-items: center;
}

.actionWithIconAndText svg {
  width: 1.8em;
  height: 1.8em;
  margin-right: 3px;
}

.actionBar svg * {
  fill: var(--text-color);
  stroke: none;
}

/* Avatar */

.avatarWrapper {
  width: var(--avatar-wrapper-size);
  height: var(--avatar-wrapper-size);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--backdrop-color);
  border-radius: 50%;
}

.avatarWrapper img.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.avatarWrapper svg {
  width: var(--avatar-generated-size);
  height: var(--avatar-generated-size);
}


@media screen and (min-width: 500px) {
   :root {
    --content-margin: 10vw;
  }
}

@media screen and (min-width: 500px) {
  :root {
   --content-margin: 5vw;
 }
}

@media screen and (min-width: 800px) {
  :root {
   --header-height: 70px;
 }
}

@media screen and (min-width: 1350px) {
  :root {
    --content-margin: 10vw;
  }
 }

@media screen and (min-width: 1900px) {
  :root {
    --content-margin: 15vw;
  }
 }