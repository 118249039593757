.BroadcastList {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
  padding-bottom: var(--player-height);
  background: var(--background-color);
}

.Broadcast {
  cursor: pointer;
  margin-bottom: var(--margin-large);
  display: flex;
  flex-flow: row nowrap;
  width: calc(100vw - 2 * var(--content-margin));
  align-items: center;
  justify-content: space-between;
}

.AvatarWrapper {
  background: var(--background-lighter-color);
  height: 76px;
  width: 76px;
  min-width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--margin-normal);
}

.AvatarWrapper img,
.AvatarWrapper svg {
  height: 87%;
  width: 87%;
  border-radius: 50%;
  object-fit: cover;
}

.Info {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  line-height: 1.3em;

}

.Description {
  display: flex;
  flex-flow: column nowrap;
}

.ServerName {
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-normal);
}

.Genre {
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Regular';
}

.Username {
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Medium';
}

.Listeners {
  display: flex;
  align-items: flex-start;
  font-size: var(--font-size-small);
}

.Listeners>div {
  align-self: flex-start;
  display: flex;
  align-items: center;
}

.Listeners svg {
  height: 1.3em;
  width: 1.3em;
  margin-right: 3px;
}

.Listeners svg * {
  fill: var(--text-color);
  stroke: none;
}


/* Sort */


.SortSelectBox {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: var(--background-color);
  transform: translateY(100%);
  z-index: 20;
  padding-bottom: 10%;
}

.SortSelectBox * {
  margin-left: var(--content-margin);
  margin-right: var(--content-margin);
}

.SortSelectBox>h3 {
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-large);
}

.Shim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: #000;
  pointer-events: none;
  opacity: 0;
}

.Shim.Open {
  pointer-events: all;
}

.SortName {
  font-size: var(--font-size-normal);
  margin: var(--margin-small) var(--content-margin);
}

.BackButton {
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-normal);
  text-align: center;
}

.SortSelectBoxToggleButton {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  margin: var(--margin-large) 0;
}

.SortSelectBoxToggleButton>svg {
  height: 1.3em;
  width: auto;
  margin-right: 4px;
}

.SortSelectBoxToggleButton>svg * {
  fill: var(--text-color);
  stroke: none;
}

.InputWrapper {
  position: relative;
  margin-top: var(--margin-extra-small);
}

.Copy {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  height: calc(100% - var(--margin-small));
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Bold';
  margin-right: var(--margin-small);
  z-index: 0;
}

.StartStreamDescription {
  margin: var(--margin-normal) 0;
  font-family: 'Satoshi-Bold';
}

.StartStreamHelpBox {
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--font-size-small);
  font-family: 'Satoshi-Medium';
  margin-top: var(--margin-large);
  align-items: center;
}

.StartStreamHelpBox svg {
  width: 5em;
  height: auto;
  margin-right: var(--margin-extra-small);
}

.StartStreamHelpBox svg * {
  fill: var(--text-color);
  stroke: none;
}

.StartStreamHelpBox a {
  color: var(--primary-color) !important;
}

.noLivestreams {
  width: calc(100vw - 2 * var(--content-margin));
  align-self: flex-start;
  margin-left: var(--content-margin);
  line-height: 1.4em;
}

.BroadcastListHeader {
  margin-bottom: 1em;
  align-self: flex-start;
  margin-left: var(--content-margin);
}

.BroadcastListHeader h1 {
  font-size: 3em;
  line-height: 1.1em;
}

.BroadcastListHeader p {
  font-size: 1.3em;
  width: 60%;
  margin-top: 32px;
}

.Broadcasts {
  border-bottom: 1px solid var(--background-lighter-color),
}

.StreamData {
  height: calc(100vh - var(--header-height));
  position: relative;
}

.StreamDataContentWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StreamDataContent {
  max-width: 460px;
  min-height: 600px;
  margin: 0 auto;
}

.BackIcon {
  position: absolute;
  top: 12px;
  left: 0;
}

.StreamDataContent label {
  margin-bottom: 4px;
}

.StreamDataContent .streamSvg {
  height: 3em !important;
  margin-top: 0.7em;
}

@media screen and (min-width: 750px) {
  .Broadcasts {
    border-top: 1px solid var(--secondary-color);
    padding-top: 24px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 2 * var(--content-margin));
  }

  .Broadcast {
    flex-direction: column;
    width: 30%;
    margin-right: 5%;
    padding-bottom: 24px;
  }

  .Broadcast:nth-child(3n) {
    margin-right: 0;
  }

  .Broadcast .AvatarWrapper {
    width: 100%;
    height: calc((100vw - 2 * var(--content-margin)) * 0.3);
    min-width: 100%;
    min-height: 100%;
    margin-right: 0;
  }

  .Broadcast .AvatarWrapper img {
    height: 92%;
    width: 92%;
  }

  .Description {
    margin-top: 12px;
  }

  .Description .Username,
  .Description .Genre {
    color: var(--color-grey);
    font-family: 'Satoshi-Regular';
  }

  .Description .Genre,
  .Description .ServerName {
    margin-top: 2px;
  }

  .Description .ServerName {
    line-height: 1.5em;
  }

  .StreamDataImageWrapper {
    display: none;
  }

}

@media screen and (min-width: 1000px) {
  .BroadcastListHeader h1 {
    margin-top: 0;
  }

  .BroadcastList>h1 {
    margin-bottom: 8px !important;
  }

  .Broadcasts {
    margin-left: var(--content-margin);
    align-self: flex-start;
    width: fit-content;
    min-width: 50%;
  }

  .Broadcast {
    flex-direction: column;
    width: 200px;
    margin-right: 32px !important;
    padding-bottom: 24px;
  }

  .Broadcast:last-child {
    margin-right: 0 !important;
  }

  .Broadcast .AvatarWrapper {
    width: 200px;
    height: 200px;
  }

  .Broadcast .AvatarWrapper img {
    height: 92%;
    width: 92%;
  }

  .StreamData {
    flex-flow: row nowrap;
    margin: 0;
    width: 100%;
  }

  .StreamData > div {
    width: 50%;
  }

  .StreamDataImageWrapper {
    display: block;
  }
  .StreamDataImageWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .BackIcon {
    left: 12px;
  }

}