.FAQEntry {
    background: var(--background-lighter-color);
    margin-bottom: 16px;
}

.QuestionWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px;
}

.CloseIcon {

   margin-left: 12px;
   position: relative;
}

.CloseIcon svg {
    width: 30px;
    height: 30px;
}

.FAQEntry h3 {
    margin: 0;

    font-family: 'Satoshi-Medium';
}

.Answer {
    overflow: hidden;
    background: var(--backdrop-color);
    margin: 0;
}

.Answer > div {
    padding: 16px;
}