.Home {
  overflow-x: hidden;
  background: var(--color-dark-grey);
}

.Home > * {
  z-index: 1;
}

.Page {
  height: calc(100vh - var(--header-height));
  position: relative;
}

.LandingPage {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100%);
  overflow: hidden;
  justify-content: center;
}

.GradientBorderWrapper {
  position: relative;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 5vh;
}

.GradientBorder {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(262deg, var(--secondary-color), transparent);
  z-index: -1;
  position: absolute;
  height: 160%;
  top: -50%;
  width: 70%;
  left: 15%;
}

.GradientBorderWrapper .GradientBlockLeft {
  position: absolute;
  height: 85px;
  top: -42.5px;
  width: 70%;
  right: 42%;
  background: linear-gradient(262deg, var(--secondary-color), transparent);
  z-index: -1;
}

.GradientBorderWrapper .GradientBlockRight {
  position: absolute;
  height: 85px;
  top: -85px;
  width: 70%;
  left: 42%;
  background: linear-gradient(262deg, transparent, var(--secondary-color));
  z-index: -1;
}

.LandingPage h1 {
  margin: 0;
  font-size: 3.2em;
  line-height: 1.1em;
  text-align: center;
}

.LandingPage h2 {
  color: var(--color-grey);
  font-family: 'Satoshi-Regular';
  text-align: center;
  font-size: var(--font-size-large);
  margin-bottom: 1em;
}

.LandingPage .JoinNowButton {
  pointer-events: all;
  padding: 12px 28px;
}

.Pixel {
  width: 100px;
  height: 100px;
  background: var(--text-color);
}

.Blue {
  background: linear-gradient(0deg, transparent 0%, var(--secondary-color) 100%);
}

.Purple {
  background: linear-gradient(0deg, transparent 0%, var(--color-purple) 100%);
}

/* InfoWithImageScreen */

.InfoWithImageScreen {
  position: relative;
  height: auto;
  width: calc(100%);
  padding-top: 1px;
}

.InfoWithImageScreen .Content {
  width: calc(100%);
  position: relative;
}

.InfoWithImageScreen .GradientBlockRight {
  position: absolute;
  height: 51px;
  top: 0px;
  width: 80%;
  left: 0;
  background: linear-gradient(262deg, transparent, var(--color-pink));
  z-index: -1;
}

.InfoWithImageScreen .GradientBlockLeft {
  position: absolute;
  height: 51px;
  top: 25px;
  width: 80%;
  right: 0;
  background: linear-gradient(262deg, var(--color-pink), transparent);
  z-index: -1;
}

.InfoWithImageScreen h1 {
  padding-top: 120px;
  font-size: 2.2em;
  line-height: 1.1em;
  margin-left: var(--content-margin);
}

.InfoWithImageScreen p,
.InfoWithPixelScreen p {
  font-size: 1.3em;
  line-height: 1.4em;
  font-family: 'Satoshi-Regular';
  width: 80%;
  margin-left: var(--content-margin);
}

.Underline {
  border-bottom: 4px solid;
  border-image-slice: 1;
  border-bottom-width: 4px;
  border-image-source: linear-gradient(262deg, transparent, var(--secondary-color));
}

.InfoWithImageScreen button {
  width: auto;
  padding: 12px 28px;
  margin-left: var(--content-margin);
  margin-bottom: 48px;
}

/* InfoWithPixelScreen */

.InfoWithPixelScreen {
  background: linear-gradient(180deg, transparent 30%, var(--background-lighter-color) 100%);
  margin-bottom: 10vh;
}

.InfoWithPixelScreen .Pixel {
  position: absolute;
  z-index: -1;
}

.InfoWithPixelScreen .Pixel:nth-child(1) {
  top: 0;
}

.InfoWithPixelScreen .Pixel:nth-child(2) {
  right: 10%;
}

.InfoWithPixelScreen h1 {
  padding-top: 30vh;
  font-size: 3em;
  width: 50%;
  line-height: 1.2em;
}

.InfoWithPixelScreen .Content {
  margin-left: var(--content-margin);
  width: calc(100% - 2 * var(--content-margin));
}

.InfoWithPixelScreen button {
  width: 38vw;
}

/* TopUsers */

.TopUsers {
  margin-left: var(--content-margin);
  width: calc(100% - 2 * var(--content-margin));
  margin-bottom: var(--player-height);
}

.TopUsers h2 {
  font-size: 3em;
  line-height: 1.1em;
  font-family: 'Satoshi-Medium';
}


.ImagesWrapper {
  width: 100%;
  position: relative;
  margin-top: var(--margin-extra-large)
}

.ImagesBackground {
  position: absolute;
  width: 100%;
  height: 50%;
  z-index: -1;
  bottom: 0;
  left: 0;
}

.ImagesWrapper>img {
  padding: 0 !important;
  width: 49%;
  height: auto;
}

.ImagesWrapper>img:nth-child(2) {
  width: 51%;
  height: auto;
  margin-bottom: 12%;
}

@media screen and (min-width: 750px) {
  .LandingPage {
    align-items: center;
  }

  .LandingPage h1 {
    max-width: 500px;
    font-size: 4em;
    margin-bottom: 0.5em;
  }

  .LandingPage h2 {
    margin-bottom: 1em;
  }

  .GradientBorderWrapper .GradientBlockLeft,
  .GradientBorderWrapper .GradientBlockRight {
    width: 100%;
    height: 128px;
  }

  .GradientBorder {
    width: 80%;
    left: 10%;
    top: -45%;
  }

  .InfoWithImageScreen .GradientBlockRight,
  .InfoWithImageScreen .GradientBlockLeft {
    height: 100px;
  }

  .InfoWithImageScreen .GradientBlockLeft {
    top: 50px;
  }

  .InfoWithImageScreen h1 {
    padding-top: 268px;
    font-size: 3em;
    max-width: 360px;
  }

  .InfoWithImageScreen p {
    width: 60%;
  }

}

@media screen and (min-width: 1000px) {

  .GradientBorderWrapper .GradientBlockLeft,
  .GradientBorderWrapper .GradientBlockRight {
    width: 110%;
    height: 128px;
    top: 50%;
    transform: translateY(-100%);
  }

  .GradientBorderWrapper .GradientBlockRight {
    top: calc(50% - 64px);
  }

  .GradientBorder {
    width: 140%;
    left: -20%;
    top: -10%;
    height: 70%;
  }

  .LandingPage h2 {
    margin-bottom: 0;
  }

  .InfoWithImageScreen {
    display: flex;
  }

  .InfoWithImageScreen .ImagesBackground {
    display: none;
  }

  .InfoWithImageScreen .ImagesWrapper {
    margin-top: 100px;
  }

  .InfoWithImageScreen .ImagesWrapper>img:nth-child(2) {
    margin-bottom: 56px;
  }

  .InfoWithImageScreen .Content {
    margin-bottom: 140px;
  }

  .InfoWithImageScreen h1 {
    padding-top: 168px;
  }

  .InfoWithImageScreen p {
    padding-top: 48px;
    width: 75%;
  }

  .TopUsers {
    width: 80%;
    margin-left: 10%;
    position: relative;
    padding-top: 38px;
  }

  .ImagesWrapper {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1350px) {
    .ImagesWrapper {
      width: 95%;
    }

    .InfoWithImageScreen button {
      margin-bottom: 86px;
    }
    .InfoWithImageScreen p {
      width: 55%;
      padding-bottom: 12px;
    }

    .TopUsers {
      width: 70%;
      margin-left: 15%;
      position: relative;
      padding-top: 38px;
    }

    .InfoWithImageScreen .Content {
      margin-bottom: 250px;
    }
}

@media screen and (min-width: 1600px) {
  .InfoWithImageScreen .Content {
    margin-bottom: 350px;
  }
}

@media screen and (min-width: 1900px) {
  .InfoWithImageScreen .Content {
    margin-bottom: 360px;
  }
  .TopUsers {
    width: 60%;
    margin-left: 20%;
  }
}

@media screen and (min-width: 2250px) {
  .InfoWithImageScreen .Content {
    margin-bottom: 460px;
  }
}

@media screen and (min-width: 2500px) {
  .InfoWithImageScreen .Content {
    margin-bottom: 560px;
  }
}