.GradientBlock {
    height: 51px;
    width: 100%;
    background: linear-gradient(90deg, var(--secondary-color), transparent);
}

.NotLoggedInNoBroadcast h1 {
    font-size: 2.2em;
    text-align: center;
    width: 70%;
    margin-left: 15%;
    margin-top: 2.2em;
}

.NotLoggedInNoBroadcast p {
    text-align: center;
    font-size: var(--font-size-normal);
    margin-bottom: 160px;

}

.NotLoggedInNoBroadcast p span {
    color: var(--primary-color);
}

.NotLoggedInNoBroadcast a {
    color: var(--primary-color);
    text-decoration: none;
    font-family: 'Satoshi-Medium';
}

@media screen and (min-width: 750px) {
    .NotLoggedInNoBroadcast {
        padding-top: 48px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }

    .GradientBlock {
        width: 80%;
    }

    .NotLoggedInNoBroadcast>* {
        margin-left: 0 !important;
    }

    .NotLoggedInNoBroadcast>h1 {
        margin-top: 1em;
        font-size: 3em;
    }
}

@media screen and (min-width: 1350px) {
    .GradientBlock {
        width: 70%;
    }

    .NotLoggedInNoBroadcast>h1 {
        width: 40%;
    }
}

@media screen and (min-width: 1900px) {

    .GradientBlock {
        width: 60%;
    }

    .NotLoggedInNoBroadcast>h1 {
        width: 25%;
    }
}