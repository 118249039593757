.ExpandedPlayer {
  width: 100%;
  background-color: var(--background-color);
  padding: 0;
  overflow-y: scroll;
  margin-bottom: var(--player-height);
}

.ExpandedPlayer svg.Icon * {
  fill: var(--text-color);
  stroke: none;
}

.Expanded {
  pointer-events: all;
}

.Hidden {
  pointer-events: none;
}

h2 {
  font-size: var(--font-size-large);
  font-family: 'Satoshi-Bold';
}

.ExpandedPlayerShim {
  position: fixed;
  height: 20vh;
  width: 100%;
  top: var(--header-height);
  left: 0;
  background: linear-gradient(180deg, rgba(28, 29, 31, 0) 0%, #151417 100%);
  transform: rotate(-180deg);
  pointer-events: none;
}

.ExpandedPlayerContent {
  width: calc(100vw - 2 * var(--content-margin));
  margin-left: var(--content-margin);
  display: flex;
  flex-flow: column nowrap;
  margin-top: var(--margin-extra-small);
  position: relative;
}

.ExpandedPlayerHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-small);
  z-index: 100;
  width: 100%;
  align-items: center;
}

.ExpandedPlayerHeader svg {
  width: 2em;
  height: 2em
}

.ExpandedPlayerHeader svg.Back {
  width: 3em;
  height: 3em;
  margin-left: -0.8em;
}

.ExpandedPlayerHeaderInfo {
  text-align: center;
}

.DurationAgo {
  font-family: 'Satoshi-Medium';
}

.ExpandedPlayerGenre {
  font-family: 'Satoshi-Medium';
  font-size: var(--font-size-small);
  text-align: left;
}

.ExpandedPlayerStreamName {
  width: 80vw;
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-large);
  margin-top: 4px;
}

.ExpandedPlayerTrack {
  border-top: 1px solid var(--backdrop-color);
  border-bottom: 1px solid var(--backdrop-color);
  padding: 10px 0;
  margin-top: var(--margin-large);
}

.ExpandedPlayerTrack .Title {
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-normal);
}

.ExpandedPlayer .Title,
.ExpandedPlayer,
.ExpandedPlayer .Artist {
  margin-top: 4px;
}

.Description {
  margin-top: var(--margin-large);
}

.ExpandedPlayerTrack .Artist {
  font-family: 'Satoshi-Medium';
  font-size: var(--font-size-small);
}

.ExpandedPlayerAvatarBig {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.ExpandedPlayerAvatarBig img,
.ExpandedPlayerAvatarBig svg {
  width: 50vw;
  height: 50vw;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  border-radius: 50%;
  z-index: 5;
}



.ExpandedPlayerPlayIconWrapper {
  position: absolute;
  background: var(--background-color);
  border-radius: 50%;
  height: 18vw;
  width: 18vw;
  max-height: 100px;
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  opacity: 0.8;
}

.ExpandedPlayerPlayIconWrapper>.ExpandedPlayerPlay {
  height: 12vw;
  width: 12vw;
  max-width: 66px;
  max-width: 66px;
}

.ActionSection {
  display: flex;
  justify-content: space-between;
}

.ArtistLabel {
  margin-top: var(--margin-large);
  margin-bottom: 0;
}

.ExpandedPlayerContent .UserDetails {
  margin: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UserDetails button {
  margin: 0;
}

.ActionSection>div {
  margin: var(--margin-normal) 0;
  display: flex;
  align-items: center;
}

.ActionSection svg {
  width: 1.6em;
  height: 1.6em;
  margin-right: var(--margin-mini);
}

.ExpandedPlayerContent .UserDetails .AvatarWrapper {
  margin-right: var(--margin-small);
  display: flex;
  background-color: var(--backdrop-color);
  align-items: center;
  justify-content: center;
  height: 3em;
  width: 3em;
}

.UserName {
  font-family: 'Satoshi-Medium';
  margin-bottom: 2px;
}

.ExpandedPlayerContent .UserDetails .AvatarWrapper img {
  border-radius: 50%;
  height: 90%;
  width: 90%;
  object-fit: cover;
}

.ExpandedPlayerContent .UserDetails .Left {
  display: flex;
  font-size: var(--font-size-normal);
}

.Circle {
  position: absolute;
  height: 63vw !important;
  width: 63vw !important;
  max-width: 380px !important;
  max-height: 380px !important;
}

.SubscriberCount {
  font-size: var(--font-size-small);
}

.TrackHistory {
  margin: var(--margin-normal) 0;
}

.TrackList {
  margin: var(--margin-small) 0;
  display: flex;
  flex-flow: column;
}

.TrackEntry {
  margin: var(--margin-extra-small) 0;
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
}

.TrackEntry.Live:first-child {
  background-color: var(--backdrop-color);
}

.TrackIndex {
  width: 2em;
}

.TrackInfo {
  font-size: var(--font-size-small);
}
