.SubscribeButton button {
  font-size: var(--font-size-normal);
  display: flex;
  align-items: center;
  padding: var(--margin-extra-small) var(--margin-small);
}

.SubscribeButton button > svg {
    width: 1.3em;
    height: 1.3em;
    margin-right: var(--margin-mini)
}

.SubscribeButton button > svg * {
    fill: var(--text-color);
    stroke: none;
}