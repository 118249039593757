.Shim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: var(--background-color);
  opacity: 0.8;
}

.AcceptCookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-lighter-color);
  padding: var(--margin-normal) var(--content-margin);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 10000;
}

.Text {
  width: 100%;
  max-width: 600px;
}

.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.Button:first-child {
  margin-right: 1em;
}