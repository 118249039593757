.SearchView {
  margin-top: var(--content-margin);
  width: calc(100vw - 2 * var(--content-margin));
}

.Closed {
  pointer-events: none !important;
}

.InputWrapper {
  position: relative;
}

.SearchInput {
  width: -webkit-fill-available;
  padding-left: 2.6em !important;
  margin: 0 !important;
}

.IconWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.IconWrapper>svg {
  height: 1.8em;
  width: 1.8em;
  pointer-events: all;
}

.SearchView .IconWrapper svg * {
  fill: var(--text-color);
  stroke: none;
}

.AvatarWrapper {
  border-radius: 50%;
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
}

.AvatarWrapper .Avatar {
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.AvatarWrapper svg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.SearchResults {
  margin: var(--margin-normal);
}

.SearchResult {
  margin-top: var(--margin-normal);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.SearchResult:hover {
  background: var(--backdrop-color);
}

.UserDetails {
  margin-left: var(--margin-normal);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (min-width: 900px) {
  .SearchView {
    width: 230px;
    margin: 0;
  }
  .SearchResults {
    display: none;
    margin: 0;
  }
  .SearchResults.Focused {
    display: block;
    position: absolute;
    background-color: var(--background-lighter-color);
    top: 100%;
    left: 0;
    width: 100%;
  }
  .SearchInput {
    height: 32px;
    padding: 0 0 0 12px !important;
  }
  .SearchResult:first-child {
    margin-top: 0;
  }
  .SearchResult {
    padding: 12px;
    font-size: var(--font-size-small);
    margin: 0;
  }

  .SearchResult .AvatarWrapper {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }

  .SearchResultUsername {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (min-width: 950px) {
  .SearchView {
    width: 280px;
  }
}