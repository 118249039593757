.NotFound {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 2*var(--header-height));
}

.NotFound p {
  margin-bottom: 10vh;
}

.NotFound button {
  width: fit-content;
  padding-left: 2.5em;
  padding-right: 2.5em;
}
