@font-face {
  font-family: Satoshi-Regular;
  src: url(./fonts/Satoshi-Regular.otf);
  font-display: swap;
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(./fonts/Satoshi-Medium.otf);
  font-display: swap;
}
@font-face {
  font-family: Satoshi-Bold;
  src: url(./fonts/Satoshi-Bold.otf);
  font-display: swap;
}
