.YellowBackground {
    color: var(--text-color-dark);
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    color: var(--text-color);
}

.YellowBackground h1 {
    margin: 0;
    padding-top: var(--margin-extra-large);
    font-size: 3.2em;
    margin-left: var(--content-margin);
}

.YellowBackground p {
    margin-left: var(--content-margin);
    font-size: 1.3em;
    line-height: 1.4em;
    font-family: 'Satoshi-Medium';
    width: 80%;
}

.YellowBackground button {
    width: fit-content;
    align-self: center;
    padding: 12px 28px;
    color: var(--text-color-dark);
}

.YellowBackground button a {
    color: var(--text-color-dark);
    text-decoration: none;
}

.DanceWrapper {
    width: 100vw;
    height: auto;
    align-self: center;
    margin: var(--margin-normal) 0;
}

.DanceWrapper svg, .DanceWrapper>div {
    width: auto;
    height: calc(100vw * (520/2501));
    display: inline-block;
}

.DanceWrapper>svg * {
    fill: var(--text-color);
}

.LoginLinkParagraph {
    align-self: center;
    font-size: var(--font-size-normal);
    font-family: 'Satoshi-Bold';
    margin: calc(2*var(--margin-normal)) 0 calc(2*var(--margin-normal));
}

.LoginLinkParagraph a {
    color: var(--text-color);
    text-decoration: none;
}

@media screen and (min-width: 750px) {

    .YellowBackground h1 {
        width: 100%;
        text-align: center;
        margin-left: 0;
        font-size: 5em;
        font-family: 'Satoshi-Medium';
        line-height: 1.2em;
        margin-top: 1em;
    }

    .YellowBackground p {
        margin-top: 2.5em;
        margin-bottom: 2em;
        width: 60%;
        text-align: center;
        margin-left: 0;
        align-self: center;
        font-size: 1.5em;
    }

    .DanceWrapper {
        margin-top: 60px;
    }

    .LoginLinkParagraph {
        margin-bottom: 5em;
    }

}

@media screen and (min-width: 1000px) {

    .YellowBackground p {
        width: 40%;
    }
}

@media screen and (min-width: 1600px) {

}


@media screen and (min-width: 1900px) {
}