.LoggedInWelcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top: 52px;
    background: var(--background-color);
}

.GradientBlock {
    height: 3px;
    width: 40%;
    background: linear-gradient(90deg, transparent, var(--secondary-color));
}

.LoggedInWelcome h1 {
    font-size: 2.2em;
    margin-top: 12px;
    font-family: 'Satoshi-Medium';
}

@media screen and (min-width: 700px) {
    .LoggedInWelcome {
        align-items: flex-end;
        padding-bottom: 128px;
    }

    .LoggedInWelcome>* {
        margin-right: var(--content-margin);
    }

    .LoggedInWelcome>h1 {
        font-size: 1em;
    }
    .GradientBlock {
        width: 20vw;
    }
}

@media screen and (min-width: 1000px) {
    .LoggedInWelcome {
        margin-bottom: 0;
    }
}