.Dialog {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 99999;
  font-size: var(--font-size-normal);
}

.Open {
  pointer-events: all;
}

button {
  text-transform: none;
}

.Box h2 {
  font-family: 'Satoshi-Bold';
  font-size: var(--font-size-large);
  margin-top: 0;
  margin-bottom: var(--margin-small);
}

.Box {
  margin: calc(4*var(--content-margin));
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: var(--background-color);
  padding: 2em var(--margin-normal);
}

.ButtonWrapper {
  display: flex;
  margin-left: -4px;
  margin-top: var(--margin-extra-small);
}


.ButtonWrapper>button:first-child {
  margin-right: 10px;
}