.HowToPage {
  margin-bottom: var(--player-height);
}

.HowToPage>h1 {
  font-size: 2.5em;
  line-height: 1.1em;
  margin-bottom: 0.5em;
}

.HowToPage>h2 {
  font-size: 1.2em;
  margin-top: 1.5em;
  font-family: 'Satoshi-Medium';
  color: var(--color-grey);
}


.HowToPage>p {
  font-size: 1.2em;
  margin-bottom: 1em;
  font-family: 'Satoshi-Regular';
  margin-top: 2em;
}

.Divider {
  width: 100%;
  border-bottom: 1px solid var(--backdrop-color);
  margin: 1em 0;
}

.SoftwareList {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 1em;
}

.FAQ>h2 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.SoftwareList>a {
  position: relative;
  width: stretch;
  margin: 6px 0;
  padding: 0.5em;
  background: var(--backdrop-color);
}

.Software {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: 0.16s ease-out;
}

.SoftwareLeftSide {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.Software h3 {
  margin: 0;
  font-size: 1.3em;
  font-family: 'Satoshi-Medium';
}

.Software svg {
  width: 54px;
  height: auto;
  margin-right: 38px;
}

.HowToPage .Software svg.ArrowRight {
  width: 24px;
  height: auto;
  justify-self: flex-end;
  margin-right: 8px;
  transition: 0.16s ease-out;
}

.HowToPage .Software:hover svg.ArrowRight {
  transform: translateX(4px);
}

.HowToPage .Software:hover {
  opacity: 0.6;
}

.Headline {
  margin-top: 20vw;
  font-family: 'Satoshi-Medium';
  font-size: var(--font-size-small);
}

.StepList {
  display: flex;
  flex-flow: column nowrap;
}

.Step {
  display: flex;
  font-family: 'Satoshi-Medium';
  font-size: var(--font-size-normal);
  margin-top: var(--margin-small);
}

.Index {
  min-width: 1.5em;
}

.Content {
  display: flex;
  flex-flow: column nowrap;
}

.Content img {
  margin: var(--margin-small) 0;
  width: 100%;
  height: auto;
  align-self: center;
  margin-top: var(--margin-small);
}

@media screen and (min-width: 700px) {
  .HowToPage {
    width: 80vw;
  }

  .Software svg {
    width: 72px;
    margin-right: 38px;
  }

  .HowToPage .Software svg.ArrowRight {
    width: 28px;
    margin-right: 12px;
  }
}

@media screen and (min-width: 1000px) {
  .HowToPage {
    width: calc(100vw - 2 * var(--content-margin));
  }
  .SoftwareList {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .SoftwareList>a {
    width: 47.5%;
    margin: calc((100vw - 2 * var(--content-margin)) * 0.01 ) 0;
  }
  .HowToPage>h1, .HowToPage > p {
    width: 50%;
  }
  .HowToPage>h1 {
    margin-top: 2em;
  }
  .FAQ  p {
    padding-right: 30%;
  }
}

@media screen and (min-width: 1400px) {
  .FAQ  p {
    padding-right: 50%;
  }
}
