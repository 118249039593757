.ErrorMessage {
  display: flex;
  align-items: center;
  color: var(--error-color);
  font-size: var(--font-size-small);
}

.ErrorIcon {
  width: 1.5em;
  height: auto;
  margin-right: 3px;
}

.ErrorMessage .ErrorIcon * {
  fill: var(--error-color);
  stroke: none;
}