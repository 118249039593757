.Toast {
  position: fixed;
  top: calc(var(--header-height) + var(--margin-small));
  left: var(--content-margin);
  width: calc(100vw - var(--content-margin) * 2);
  display: flex;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  z-index: 900;
  background: var(--backdrop-color);
  font-size: var(--font-size-normal);
}

.Toast > div {
  margin: 10px;
}

.Toast h2 {
  font-size: var(--font-size-normal);
  margin: 0;
}

.LeftAlign {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.TypeIcon svg, .Close svg {
  cursor: pointer;
  width: 2em;
  height: 2em;
}

.TypeIcon svg {
  margin-right: var(--margin-small);
}

.TypeIcon svg * {
  stroke: none;
}

.TypeIcon.success svg *,
.TypeIcon.info svg * {
  fill: var(--success-color);
}

.TypeIcon.error svg *,
.TypeIcon.warning svg * {
  fill: var(--error-color);
}

.Close svg * {
  fill: var(--text-color);
  stroke: none;
}

@media screen and (min-width: 900px) {
  .Toast {
    width: 415px;
    left: calc(100% - 415px - 18px);
  }
}